import React from 'react';
import { PendingDeliveryIcon, KitDeliveredIcon, CollectedKitIcon } from '../icons/Icons'

import './EmptyFeed.scss';

const EmptyFeed = ({ screen }) => {
    return (
        <div className="empty-feed">
            {
                screen === 'pending' &&
                <React.Fragment>
                    <PendingDeliveryIcon/>
                    <h4>No Assigned Request</h4>
                    <p>You do not any assigned delivery at moment.</p>
                </React.Fragment>
            }
            {
                screen === 'delivered' &&
                <React.Fragment>
                    <KitDeliveredIcon/>
                    <h4>No Completed Delivery</h4>
                    <p>You do not have any completed delivery at the moment.</p>
                </React.Fragment>
            }
            {
                screen === 'collected' &&
                <React.Fragment>
                    <CollectedKitIcon/>
                    <h4>No Kit Collected</h4>
                    <p>You have not collected any kit at the moment.</p>
                </React.Fragment>
            }
        </div>
    )
}

export default EmptyFeed;
