import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRequest } from '../../api/Api';
import Header from '../../components/header/Header';
import Nav from '../../components/nav/Nav';
import Tabs from '../../components/tabs/Tabs';
import HistoryCompleted from '../../components/historyCompleted/HistoryCompleted';
import Loader from '../../components/loader/Loader';

import './History.scss';

class History extends Component {
    state = {
        tab: 'completed',
        history: [],
        error: '',
    }

    componentDidMount() {
        const { tab } = this.state;
        this.loadRequests(tab);
        
    }

    loadRequests = async (tab) => {
        this.setState({ loading: true })
        const params = { status: tab };
        // if (tab==='completed') params = { status: 'sample_delivered'};
        // if (tab==='failed') params = { status: tab};
        
        const result = await getRequest('rider/history', params);
        const { response, error } = result;
        console.log(response);
        this.setState({
            loading: false,
            history: response ? response : [],
            error,
        })
    }

    handleTabs = (tab) => {
        this.setState({ tab }, () => this.loadRequests(tab));
    }

    navigate = (path, id) => {
        this.props.history.push({
            pathname: `/${path}`,
            id,
        })
    }

    render() {
        const { tab, loading, error, history } = this.state;
        const tabs = ['completed', 'failed']
        return (
            <React.Fragment>
                <Header title="History" />
                {loading && <Loader />}
                <div className="history-screen">
                    <Tabs tabs={tabs} tab={tab} handleTabs={this.handleTabs} />
                    {error && <p className="page-error">{error}</p>}
                    {/* {
                        tab==='completed' && */}
                        <HistoryCompleted history={history}/>
                    {/* } */}
                    {/* {
                        tab==='failed' &&
                        <HistoryCompleted />
                    } */}
                </div>
                <Nav page="history"/>
            </React.Fragment>
        )
    }
}

export default withRouter(History);
