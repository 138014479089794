import React from 'react';
import { generateColor } from '../../utils/helper';
import EmptyFeed from '../emptyFeed/EmptyFeed';

import { CheckIcon } from '../icons/Icons.js';

import './Completed.scss';

const Completed = ({ navigate, requests, loading }) => {
    return (
        <div className="completed-list">
            {
                !loading && requests.length > 0 &&
                requests.map(request => {
                    const { id, kitCode, case: person } = request;
                    const { id: case_id, firstName, lastName } = person;
                    return (
                        <div className="completed-card" key={id} onClick={() => navigate(`/account/${id}/${kitCode}/${case_id}`)}>
                            <div className="badge" style={{ background: generateColor() }}>{firstName[0]}{lastName[0]}</div>
                            <div className="name-number">
                                <h4>{firstName} {lastName}</h4>
                                <p>Kit number: {kitCode}</p>
                            </div>
                            <CheckIcon />
                        </div>
                    )
                })
            }
            {
                !loading && requests.length === 0 &&
                <EmptyFeed screen="collected" />
            }
        </div>
    )
}

export default Completed;
