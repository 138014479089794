import React from 'react';
import PropTypes from 'prop-types';
import ModalLoader from '../modal/ModalLoader';

//styles for classes .modal-text, .modal-button in Modal.scss

const ConfirmModal = ({ children, title, buttonText, cancel, action, loading = false, error }) => {
    return (
        <div className="ConfirmModal-div">
            <div className="modal-text">
                {title}
                {children}
            </div>
            {loading && <ModalLoader />}
            <div className="modal-buttons">
                {error && <p className="error">{error}</p>}
                <button className="primary-button" onClick={action} >{buttonText}</button>
                <button className="primary-button-outline" onClick={cancel}>Cancel</button>
            </div>
        </div>
    )
}

ConfirmModal.propTypes = {
    title: PropTypes.string,
    buttonText: PropTypes.string,
    cancel: PropTypes.func,
    loading: PropTypes.bool,
}

export default ConfirmModal;
