import React from 'react'

const Loader = () => {
    return (
        <div className="modal-loader loader">
            <div className="bar"></div>
        </div>
    )
}

export default Loader;
