import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { putRequest } from '../../api/Api';
import TitleHeader from '../../components/header/TitleHeader';
import Nav from '../../components/nav/Nav';
import Loader from '../../components/loader/Loader';
import Modal from '../../components/modal/Modal';
import SuccessAnimation from '../../components/successAnimation/SuccessAnimation';

import './ChangePassword.scss';

class ChangePassword extends Component {
    state = {
        currentPassword: '',
        newPassword: '',
    }

    handleInputs = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    updatePassword = async () => {
        this.setState({ loading: true })
        const { newPassword, currentPassword } = this.state;
        const body = { newPassword, current: currentPassword };
        const result = await putRequest('rider/password/change', body);
        const { response, error } = result;
        if(response){
            this.openModal();
        }
        this.setState({
            loading: false,
            error,
        })
    }

    openModal = () => {
        this.setState({ modalClass: 'animated-modal' })
    }

    closeModal = () => {
        this.setState({ modalClass: 'animated-modal out' }, () => this.goTo('/profile'))
    }

    goTo = (pathname) => {
        this.props.history.push({
            pathname
        })
    }

    render() {
        const { currentPassword, newPassword, modalClass, loading, error } = this.state;
        return (
            <div className="change-password-screen">
                <TitleHeader title="Edit Password" />
                {loading && <Loader />}
                {error && <p className="page-error">{error}</p>}
                <div className="input-container">
                    <label htmlFor="currentPassword">
                        <span className="input-name">Current Password</span>
                        <input 
                            type="password"
                            id="currentPassword"
                            name="currentPassword"
                            onChange={this.handleInputs}
                            placeholder="Required"
                            value={currentPassword}
                        />
                    </label>
                    <label htmlFor="newPassword">
                        <span className="input-name">New Password</span>
                        <input 
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            onChange={this.handleInputs}
                            placeholder="Required"
                            value={newPassword}
                        />
                    </label>
                </div>
                <button 
                    className="primary-button"
                    disabled={!currentPassword || !newPassword}
                    onClick={this.updatePassword}    
                >
                    Save Changes
                </button>
                <Nav page="profile"/>

                <Modal modalClass={modalClass}>
                    <SuccessAnimation close={this.closeModal} />
                </Modal>
            </div>
        )
    }
}

export default withRouter(ChangePassword);
