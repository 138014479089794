import React from 'react';
import check_animation from '../../assets/check-loop.gif';
import close_icon from '../../assets/close-fill.svg';

import './SuccessAnimation.scss';

const SuccessAnimation = ({ close }) => {
    return (
        <div className="success-div">
            <img src={close_icon} alt="close-icon" className="close-icon" onClick={close}/>
            <img src={check_animation} alt=""/>
        </div>
    )
}

export default SuccessAnimation;
