import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { getRequest, putRequest } from '../../api/Api';
import TitleHeader from '../../components/header/TitleHeader';
import Nav from '../../components/nav/Nav';
import Modal from '../../components/modal/Modal';
import SuccessAnimation from '../../components/successAnimation/SuccessAnimation';
import Loader from '../../components/loader/Loader';
import { generateColor } from '../../utils/helper';

import check_icon from '../../assets/check-icon.svg';
import background_icon_1 from '../../assets/account-left.svg';
import background_icon_2 from '../../assets/account-right.svg';

import './Account.scss';

class Account extends Component {
    state = {
        modalClass: '',
        // modalClass: 'animated-modal'
        // status: 'pending',
        sampleCollected: false,
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { code, id, case_id } = params;
        this.setState({
            code,
            id,
            case_id
        })
        this.loadRequest(code, case_id);
    }

    loadRequest = async (code, id) => {
        this.setState({ loading: true })
        let params = { kitCode: code, caseId: id };

        const result = await getRequest('rider/scan/kit', params);
        const { response, error } = result;
        this.setState({
            loading: false,
            request: response ? response : null,
            error,
        })
    }

    openModal = () => {
        this.setState({ modalClass: 'animated-modal' })
    }

    closeModal = () => {
        const { request: { status } } = this.state;
        this.setState({ modalClass: 'animated-modal out' }, () => this.goBack(status==='pending' ? '/home/pending' : null))
    }



    updateDelivery = async (endpoint) => {
        this.setState({ loading: true })
        const { request: { id } } = this.state;
        const result = await putRequest(`${endpoint}/${id}`);
        const { response, error } = result;
        if(response){
            this.openModal();
        }
        this.setState({
            loading: false,
            error,
        })
    }

    toggleConfirmation = () => {
        this.setState({ sampleCollected: !this.state.sampleCollected })
    }

    goBack = (route) => {
        const { request, id, case_id } = this.state;
        const status = request && request.status;
        let path;
        if (route) {
            path = route;
        } else {
            if (status === 'pending') path = `/request/pending/${id}`;
            if (status === 'kit_delivered') path = '/home/kit delivered';
            if (status === 'sample_collected') path = '/home/collected';
            if (!status) path = `/scan-qr/${id}/${case_id}`;
        }
        this.props.history.push({
            pathname: path,
        })
    }

    render() {
        const { modalClass, loading, request, error } = this.state;
        const tester = request && request.case;
        const status = request && request.status;
        return (
            <div className="account-screen">
                <TitleHeader title="Account" goBack={() => this.goBack()}/>
                {loading && <Loader />}
                {error && !error.includes('400') && <p className="page-error">{error}</p>}
                <div className="account-details">
                    <div className="details-top">
                        {
                            request && 
                                <React.Fragment>
                                <div className="badge" style={{ background: generateColor() }}>
                                    {tester.firstName[0]}{tester.lastName[0]}
                                </div>
                                <h4 className="name">{tester.firstName} {tester.lastName}</h4>
                                <p className="text">
                                    Delivery requested {moment(tester.deliveryDate).format('MMM D, YYYY h:mmA')}
                                </p>
                            </React.Fragment>
                        }
                        {
                            error && error.includes('400') &&
                            <p className="text">
                                The kit you scanned isn't attached to this person, please cross check and scan again.
                            </p>
                        }
                        <img src={background_icon_1} alt="" className="left"/>
                        <img src={background_icon_2} alt="" className="right"/>
                    </div>
                    <div className="details-bottom">
                        {
                            request &&
                            <React.Fragment>
                                <div className="item-row">
                                    <p className="label">Gender</p>
                                    <p className="content">{tester.gender}</p>
                                </div>
                                <div className="item-row">
                                    <p className="label">Phone Number</p>
                                    <p className="content">{tester.phone_no}</p>
                                </div>
                                <div className="item-row">
                                    <p className="label">Address</p>
                                    <p className="content">{tester.deliveryAddress}</p>
                                </div>
                                {
                                    status === 'pending' &&
                                    <button className="primary-button"
                                        onClick={() => this.updateDelivery('rider/kit/delivered')}
                                    >
                                        <img src={check_icon} alt=""/>
                                        Deliver Kit
                                    </button>
                                }
                                {
                                    status === 'kit_delivered' &&
                                    <React.Fragment>
                                        {/* <div className="collect-sample" onClick={this.toggleConfirmation}>
                                            <div className={`check ${sampleCollected ? 'checked' : ''}`}>
                                                <div className="dot"></div>
                                            </div>
                                            <p>indicate sample has been collected</p>
                                        </div> */}
                                        <button className="primary-button"
                                            onClick={() => this.updateDelivery('rider/sample/collected')}
                                            // disabled={!sampleCollected}
                                        >
                                            <img src={check_icon} alt=""/>
                                            Collect Sample
                                        </button>
                                    </React.Fragment>
                                }
                                {
                                    status === 'sample_collected' &&
                                    <button className="primary-button"
                                        onClick={() => this.updateDelivery('rider/sample/delivered')}
                                    >
                                        <img src={check_icon} alt=""/>
                                        Deliver Sample
                                    </button>
                                }
                            </React.Fragment>
                        }
                        {
                            error && error.includes('400') &&
                            <button className="primary-button"
                                onClick={() => this.goBack()}
                                style={{marginTop:0}}
                            >
                                Scan Again
                            </button>
                        }
                    </div>
                </div>
                <Modal modalClass={modalClass}>
                    <SuccessAnimation close={this.closeModal} />
                </Modal>
                <Nav page="home" />
            </div>
        )
    }
}

export default withRouter(Account);
