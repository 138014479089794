import React from 'react';
import { generateColor } from '../../utils/helper';
import EmptyFeed from '../emptyFeed/EmptyFeed';

import { ClockIcon } from '../icons/Icons.js';

import './Delivered.scss';

const Delivered = ({ requests, loading, navigate }) => {
    return (
        <div className="delivered-list">
            {
                !loading && requests.length > 0 &&
                requests.map(request => {
                    const { id, kitCode, case: person, } = request;
                    const { id: case_id, firstName, lastName, phone_no } = person;
                    return (
                        <div className="delivered-card" key={id} onClick={() => navigate(`/account/${id}/${kitCode}/${case_id}`)}>
                            <div className="badge" style={{ background: generateColor() }}>{firstName[0]}{lastName[0]}</div>
                            <div className="name-number">
                                <h4>{firstName} {lastName}</h4>
                                <p>{phone_no}</p>
                            </div>
                            <ClockIcon />
                        </div>
                    )
                })
            }
            {
                !loading && requests.length === 0 &&
                <EmptyFeed screen="delivered" />
            }
        </div>
    )
}

export default Delivered;
