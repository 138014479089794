import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ customStyle, handleClick, text }) => {
    return (
        <button className={customStyle} onClick={handleClick} >
            {text}
        </button>
    )
}

Button.propTypes = {
    customStyle: PropTypes.string,
    handleClick: PropTypes.func,
    text: PropTypes.string
}

export default Button;
