import React from 'react';
import PropTypes from 'prop-types';
import { generateColor } from '../../utils/helper';
import EmptyFeed from '../emptyFeed/EmptyFeed';

import { PhoneIcon } from '../icons/Icons.js';

import './Pending.scss';

const Pending = ({ navigate, requests, loading }) => {
    return (
        <div className="pending-list">
            {
                !loading && requests.length > 0 &&
                requests.map(request => {
                    const { id, case: person } = request;
                    const { firstName, lastName, phone_no } = person;
                    return (
                        <div className="pending-card" key={id}>
                            <div className="tap-div" onClick={() => navigate(`/request/pending/${id}`)}>
                                <div className="badge" style={{ background: generateColor() }}>{firstName[0]}{lastName[0]}</div>
                                <div className="name-number">
                                    <h4>{firstName} {lastName}</h4>
                                    <p>{phone_no}</p>
                                </div>
                            </div>
                            <a href={`tel:${phone_no}`}>
                                <button>
                                    <PhoneIcon />
                                            Call
                                </button>
                            </a>
                        </div>
                    )
                })
            }
            {
                !loading && requests.length === 0 &&
                <EmptyFeed screen="pending" />
            }
        </div>
    )
}

Pending.propTypes = {
    navigate: PropTypes.func,
    requests: PropTypes.array,
    loading: PropTypes.bool
}

export default Pending;
