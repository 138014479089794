import React from 'react'

const ModalLoader = () => {
    return (
        <div className="modal-loader">
            <div className="bar"></div>
        </div>
    )
}

export default ModalLoader;
