import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRequest } from '../../api/Api';
import Header from '../../components/header/Header';
import Nav from '../../components/nav/Nav';
import Tabs from '../../components/tabs/Tabs';
import Pending from '../../components/pending/Pending';
import Completed from '../../components/completed/Completed';
import Delivered from '../../components/delivered/Delivered';
import Loader from '../../components/loader/Loader';

import './Home.scss';

class Home extends Component {
    state = {
        tab: 'pending',
        requests: [],
        error: ''
    }


    componentDidMount() {
        const { match: { params } } = this.props;
        const { tab } = params;
        this.setState({
            tab
        })
        this.loadRequests(tab);
    }

    handleTabs = (tab) => {
        this.setState({ tab }, () => {
            this.props.history.push({
                pathname: `/home/${tab}`
            })
            this.loadRequests(tab);
        });
    }

    navigate = (path) => {
        this.props.history.push({
            pathname: path,
        })
    }

    loadRequests = async (tab) => {
        this.setState({ loading: true })
        let params = {};
        if (tab==='pending') params = { status: tab};
        if (tab==='kit delivered') params = { status: 'kit_delivered'};
        if (tab==='collected') params = { status: 'sample_collected'};
        
        const result = await getRequest('rider/requests', params);
        const { response, error } = result;
        this.setState({
            loading: false,
            requests: response ? response : [],
            error,
        })
    }

    render() {
        const { tab, requests, loading, error } = this.state;
        const tabs = ['pending', 'kit delivered', 'collected']
        return (
            <React.Fragment>
                <Header title="Requests" />
                {loading && <Loader />}
                <div className="home-screen">
                    <Tabs tabs={tabs} tab={tab} handleTabs={this.handleTabs} />
                    {error && <p className="page-error">{error}</p>}
                    {
                        !error && 
                        <React.Fragment>
                            {
                                tab === 'pending' &&
                                <Pending navigate={this.navigate} requests={requests} loading={loading}/>
                            }
                            {
                                tab === 'kit delivered' &&
                                <Delivered navigate={this.navigate} requests={requests} loading={loading}/>
                            }
                            {
                                tab === 'collected' &&
                                <Completed navigate={this.navigate} requests={requests} loading={loading}/>
                            }
                        </React.Fragment>
                    }
                </div>
                <Nav page="home" />
            </React.Fragment>
        )
    }
}

export default withRouter(Home);