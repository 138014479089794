import React from 'react';
import PropTypes from 'prop-types';

import './Tabs.scss';

const Tabs = ({ tabs, tab, handleTabs }) => {
    return (
        <div className="tabs">
            {
                tabs.map((item, index) => (
                    <div 
                        className={`tab ${tab===item? 'active': ''}`} 
                        onClick={() => handleTabs(item)}
                        key={index}
                    >
                        {item}
                    </div>
                ))
            }
        </div>
    )
}

Tabs.propTypes = {
    tab: PropTypes.string,
    handleTabs: PropTypes.func,
    tabs: PropTypes.array
}

export default Tabs
