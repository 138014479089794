import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import ConfirmModal from '../confirmModal/ConfirmModal';
import Button from '../Button';
import Modal from '../modal/Modal';

import './Header.scss';

class Header extends Component {
    state = {
        modalClass: '',
        // modalClass: 'animated-modal'
    }

    openModal = () => {
        this.setState({ modalClass: 'animated-modal' })
    }

    closeModal = () => {
        this.setState({ modalClass: 'animated-modal out' })
    }

    signout = () => {
        localStorage.removeItem('access_token');
        this.props.history.replace({
            pathname: '/login',
        })
    }

    render() {
        const { modalClass } = this.state;
        const { title } = this.props;
        return (
            <React.Fragment>
                <header className="header">
                    <h3>{title}</h3>
                    <Button
                        customStyle="red-button signout"
                        text="Signout"
                        handleClick={this.openModal}
                    />
                </header>
                <Modal modalClass={modalClass} >
                    <ConfirmModal
                        title="Are you sure you want to signout?"
                        buttonText="Sign me out"
                        action={this.signout}
                        cancel={this.closeModal}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

Header.propTypes = {
    title: PropTypes.string,
}

export default withRouter(Header);
