import React from 'react';
// import Routes from './routes/Routes';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ProtectedRoute } from './authentication/Authentication';

import Login from './pages/login/Login';
import Home from './pages/home/Home';
import History from './pages/history/History';
import Profile from './pages/profile/Profile';
import EditProfile from './pages/editProfile/EditProfile';
import ChangePassword from './pages/changePassword/ChangePassword';
import PendingRequest from './pages/pendingRequest/PendingRequest';
import CompletedRequest from './pages/completedRequest/CompletedRequest';
import Account from './pages/account/Account';
import Scanner from './pages/scanner/Scanner';

import './App.scss';

function App() {
  return (
        <Router>
        <Switch>
          <Route exact path='/' render={() => (
            <Redirect to='/home/pending' />
          )} />
          <Route path='/login' component={Login} />
          <ProtectedRoute path='/home/:tab' component={Home}/>
          <ProtectedRoute path='/history' component={History}/>
          <ProtectedRoute exact path='/profile' component={Profile}/>
          <ProtectedRoute exact path='/profile/edit' component={EditProfile}/>
          <ProtectedRoute exact path='/profile/password' component={ChangePassword}/>
          <ProtectedRoute path='/account/:id/:code/:case_id' component={Account}/>
          <ProtectedRoute path='/scan-qr/:id/:case_id' component={Scanner}/>
          <ProtectedRoute exact path='/request/pending/:id' component={PendingRequest}/>
          <ProtectedRoute exact path='/request/completed' component={CompletedRequest}/>
        </Switch>
      </Router>
  );
}

export default App;
