import { randomColor } from 'randomcolor';

export const generateColor = () => {
    return randomColor({
        luminosity: 'dark',
        format: 'rgb',
    });
}

export const signout = () => {
    localStorage.removeItem('access_token');
    window.location.reload();
}