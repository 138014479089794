import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { postRequest } from '../../api/Api';
import Button from '../../components/Button';
import Loader from '../../components/loader/Loader';
import lifebank_logo from '../../assets/lifebank-logo.svg';
import left_illustration from '../../assets/login-illustration-left.svg';
import right_illustration from '../../assets/login-illustration-right.svg';

import './Login.scss';

class Login extends Component {
    state = {
        showPassword: false,
        username: '',
        password: '',
        loading: false,
    }

    handleInputs = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'Error']: '',
        })
    }

    togglePasswordVisibility = () => {
        this.setState({
            showPassword: !this.state.showPassword,
        })
    }

    validate = () => {
        const { username, password } = this.state;
        if (username && password) {
            this.login()
        }else{
            this.setState({
                usernameError: !username ? 'Enter your username' : '',
                passwordError: !password? 'Enter your password' : ''
            })
        }
    }

    login = async () => {
        const { username, password } = this.state;
        const requestBody = {
            refId: username,
            password
        }
        this.setState({ loading: true })
        const result = await postRequest('rider/login', requestBody);
        const { response, error } = result;
        this.setState({
            serverError: error,
            loading: false,
        })
        if (response) {
            localStorage.setItem('access_token', response.token);
            this.props.history.replace({
                pathname: '/home/pending',
            })
        }
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } }
        const token = localStorage.getItem('access_token');
        if (token) {
          return <Redirect to={from} />;
        }
        const { username, password, showPassword, loading, usernameError, passwordError, serverError } = this.state
        return (
            <div className="login-page">
                <img src={lifebank_logo} alt="LifeBank logo" className="lifebank-logo"/>
                <div className="login-card">
                    {loading && <Loader />}
                    <h3 className="title">Welcome Back</h3>
                    <h4 className="subtitle">Sign in to continue</h4>
                    <label htmlFor="username">
                        <span className="input-name">Username</span>
                        <span className="input-error">{usernameError}</span>
                        <input 
                            type="text"
                            id="username"
                            name="username"
                            onChange={this.handleInputs}
                            value={username}
                            className={usernameError ? 'error' : ''}
                        />
                    </label>
                    <label htmlFor="password">
                        <span className="input-name">Password</span>
                        <span className="input-error">{passwordError}</span>
                        <input
                            type={ showPassword ? 'text' : 'password' }
                            id="password"
                            name="password"
                            onChange={this.handleInputs}
                            value={password}
                            className={passwordError ? 'error' : ''}
                        />
                        {
                            password && 
                            <span className="show-hide" onClick={this.togglePasswordVisibility}>
                                { showPassword ? 'Hide' : 'Show' }
                            </span>
                        }
                    </label>
                    <p className="server-error">{serverError}</p>
                    <p className="forgot-password">Forgot your password?</p>
                    <Button 
                        customStyle="primary-button"
                        text="login"
                        handleClick={this.validate}
                    />
                </div>
                <img src={left_illustration} alt="" className="left-illustration"/>
                <img src={right_illustration} alt="" className="right-illustration"/>
            </div>
        )
    }
}

export default withRouter(Login);
