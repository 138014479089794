import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TitleHeader from '../../components/header/TitleHeader';
import Nav from '../../components/nav/Nav';
import QrReader from 'react-qr-reader'

import './Scanner.scss';

class Scanner extends Component {
    state = {
        code: ''
    }

    componentDidMount(){
        const { match: { params: { id, case_id } } } = this.props;
        this.setState({id, case_id});
    }

    handleScan = (data) => {
        const {id, case_id} = this.state;
        if(data){
            this.setState({
                code: data,
            }, () => this.navigate(data, id, case_id))
        }
    }

    navigate = (code, id, case_id) => {
        this.props.history.push({
            pathname: `/account/${id}/${code}/${case_id}`,
        })
    }

    handleError = (err) => {
        console.error(err)
    }

    goBack = (route) => {
        this.props.history.push({
            pathname: route,
        })
    }

    render() {
        const previewStyle = {
            height: 'auto',
            width: '100%',
            borderRadius: '6px'
        }
        const { id, case_id } = this.state;
        const { kitCode } = this.props.location;
        // const kitCode = '7654BV';
        return (
            <div className="scanner-screen">
                <TitleHeader title="Scan QR Code" />
                <div className="scanner-content">
                    <QrReader
                        delay={1000}
                        style={previewStyle}
                        onError={this.handleError}
                        onScan={this.handleScan}
                        facingMode='environment'
                    />
                    <p onClick={() => this.navigate(kitCode, id, case_id)}>skip scan dev</p>
                    <p className="scan-text">Scanning code...</p>
                    <button className="cancel-button" onClick={() => this.goBack(`/request/pending/${id}`)}>Cancel</button>
                </div>
                <Nav page="home" />
            </div>
        )
    }
}

export default withRouter(Scanner);
