import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRequest } from '../../api/Api';
import Header from '../../components/header/Header';
import Nav from '../../components/nav/Nav';
import Loader from '../../components/loader/Loader';

import './Profile.scss';

class Profile extends Component {
    state = {
        error: '',
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile = async () => {
        this.setState({ loading: true })
        
        const result = await getRequest('profile');
        const { response, error } = result;
        this.setState({
            loading: false,
            ...response,
            error,
        })
    }

    goTo = (pathname) => {
        this.props.history.push({
            pathname
        })
    }

    render() {
        const {
            loading,
            error,
            refId,
            firstName,
            lastName,
            email,
            phone,
            address
        } = this.state;
        return (
            <div className="profile-screen">
                <Header title="Profile" />
                {loading && <Loader />}
                <div className="top-card">
                    {error && <p className="page-error">{error}</p>}
                    <div className="name-id-badge">
                        <div className="badge">{firstName ? firstName[0] : '-'}{lastName ? lastName[0] : '-'}</div>
                        <div className="name-id">
                            <h4 className="name">{firstName ? firstName : '....'} {lastName? lastName : '....'}</h4>
                            <p className="id">ID: {refId? refId : '...'}</p>
                        </div>
                    </div>
                    <div className="line"></div>
                    <div className="buttons">
                        <button className="primary-button" onClick={() => this.goTo('/profile/edit')}>Edit Profile</button>
                        <button className="primary-button-outline" onClick={() => this.goTo('/profile/password')}>Change Password</button>
                    </div>
                </div>
                <div className="information">
                    <h4>Personal Information</h4>
                    <div className="info-cards">
                        <div className="info-card half">
                            <h5 className="title">Firstname</h5>
                            <p className="content">{firstName}</p>
                        </div>
                        <div className="info-card half">
                            <h5 className="title">Lastname</h5>
                            <p className="content">{lastName}</p>
                        </div>
                        <div className="info-card">
                            <h5 className="title">Email</h5>
                            <p className="content">{email}</p>
                        </div>
                        <div className="info-card">
                            <h5 className="title">Phone Number</h5>
                            <p className="content">{phone}</p>
                        </div>
                        <div className="info-card address">
                            <h5 className="title">Address</h5>
                            <p className="content">{address}</p>
                        </div>
                    </div>
                </div>
                <div className="manage-account">
                    <h4>Account Management</h4>
                    <div className="diable-account">
                        <p>Disable Account</p>
                    </div>
                </div>
                <Nav page="profile"/>
            </div>
        )
    }
}

export default withRouter(Profile);
