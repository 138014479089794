import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import back_icon from '../../assets/back-icon.svg';

import './TitleHeader.scss'

const TitleHeader = ({title, goBack, history}) => {
    const back = () => {
        history.goBack();
    }
    return (
        <div className="title-header">
            <div className="back" onClick={() => {goBack ? goBack() : back()}}> 
                <img src={back_icon} alt=""/>
                back
            </div>
            <h3>{title}</h3>
        </div>
    )
}

TitleHeader.propTypes = {
    title: PropTypes.string,

}

export default withRouter(TitleHeader);
