import axios from "axios";
import { signout } from '../utils/helper';

// const baseUrl = 'http://46.101.187.66:3333/';
// const baseUrl = 'https://self-testing.herokuapp.com/';
const baseUrl = `${process.env.REACT_APP_URL}`;

export const getRequest = async (endpoint, params) => {
    const token = localStorage.getItem('access_token');
    let response = null;
    let error = null;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params,
    };

    await axios.get(baseUrl + endpoint, config)
    .then(res => response = res.data)
    .catch(e => {
        if(e.response.status === 401) {
            signout();
        }
        error = e.toJSON().message;
    });

    const result = {
        response,
        error,
    }

    //console.log(result);

    return result;
}

export const postRequest = async (endpoint, requestBody) => {
    const token = localStorage.getItem('access_token');
    let response = null;
    let error = null;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    await axios.post(baseUrl + endpoint, {
        ...requestBody
    }, config).then(res => response = res.data)
    .catch(e => {
        if(e.response.status === 401) {
            signout();
        }
        error = e.toJSON().message;
    });
    
    const result = {
        response,
        error,
    }

    //console.log(result);

    return result;
}

export const putRequest = async (endpoint, requestBody) => {
    const token = localStorage.getItem('access_token');
    let response = null;
    let error = null;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    await axios.put(baseUrl + endpoint, {
        ...requestBody
    }, config).then(res => response = res.data)
    .catch(e => {
        if(e.response.status === 401) {
            signout();
        }
        error = e.toJSON().message;
    });
    
    const result = {
        response,
        error,
    }

    //console.log(result);

    return result;
}