import React from "react";
import { Link } from "react-router-dom";
import {
  HomeActiveIcon,
  HomeDefaultIcon,
  HistoryActiveIcon,
  HistoryDefaultIcon,
  ProfileActiveIcon,
  ProfileDefaultIcon,
} from "../icons/NavIcons";

import "./Nav.scss";

const Nav = ({ page }) => {
  return (
    <div className="nav-footer">
      <Link to="/home/pending" style={{ textDecoration: "none" }}>
        <div className="nav-item">
          {page === "home" ? <HomeActiveIcon /> : <HomeDefaultIcon />}
          <p className={page === "home" ? "active" : "default"}>Home</p>
        </div>
      </Link>
      <Link to="/history" style={{ textDecoration: "none" }}>
        <div className="nav-item">
          {page === "history" ? <HistoryActiveIcon /> : <HistoryDefaultIcon />}
          <p className={page === "history" ? "active" : "default"}>History</p>
        </div>
      </Link>
      <Link to="/profile" style={{ textDecoration: "none" }}>
        <div className="nav-item">
          {page === "profile" ? <ProfileActiveIcon /> : <ProfileDefaultIcon />}
          <p className={page === "profile" ? "active" : "default"}>Profile</p>
        </div>
      </Link>
    </div>
  );
};

export default Nav;
