import React from 'react';
import PropTypes from 'prop-types';

import './Modal.scss';

const Modal = ({ modalClass, children }) => {
    return (
        <div id="modal-container" className={modalClass}>
            <div className="modal-background">
                <div className="modal">
                    {children}
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    modalClass: PropTypes.string,
    children: PropTypes.node
}

export default Modal;
// export default class Modal extends Component {
//     state = {
//         modalClass: false,
//     }
//     openModal = () => {
//         this.setState({ modalClass: 'animated-modal' })
//     }
    
//     closeModal = () => {
//         this.setState({ modalClass: 'animated-modal out' })
//     }
//     render() {
//         const { modalClass } = this.state;
//         return (
//             <div>
//                 <div id="modal-container" className={modalClass}>
//                     <div className="modal-background">
//                         <div className="modal">
//                             <h2>I'm a Modal</h2>
//                             <p>Hear me roar.</p>
//                             <button onClick={() => this.closeModal()}>close</button>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="content">
//                     <h1>Modal Animations</h1>
//                     <div className="buttons">
//                         <div className="button" onClick={() => this.openModal()}>Meep Meep</div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }
