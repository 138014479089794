import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRequest, putRequest } from '../../api/Api';
import TitleHeader from '../../components/header/TitleHeader';
import Nav from '../../components/nav/Nav';
import Loader from '../../components/loader/Loader';
import Modal from '../../components/modal/Modal';
import SuccessAnimation from '../../components/successAnimation/SuccessAnimation';

import './EditProfile.scss';

class EditProfile extends Component {
    state = {
        profile: null,
    }

    componentDidMount() {
        this.loadProfile();
    }

    loadProfile = async () => {
        this.setState({ loading: true })

        const result = await getRequest('profile');
        const { response, error } = result;
        this.setState({
            loading: false,
            profile: response ? response : null,
            error,
        })
    }

    updateProfile = async () => {
        this.setState({ loading: true })
        const { profile: { firstName, lastName, email, phone, address } } = this.state;
        const body = { firstName, lastName, email, phone, address };
        const result = await putRequest('profile/update', body);
        const { response, error } = result;
        if(response){
            this.openModal();
        }
        this.setState({
            loading: false,
            error,
        })
    }

    openModal = () => {
        this.setState({ modalClass: 'animated-modal' })
    }

    closeModal = () => {
        this.setState({ modalClass: 'animated-modal out' }, () => this.goTo('/profile'))
    }

    goTo = (pathname) => {
        this.props.history.push({
            pathname
        })
    }


    handleInputs = (e) => {
        this.setState({
            profile: { ...this.state.profile, [e.target.name]: e.target.value }
        })
    }

    render() {
        const {
            profile,
            loading,
            error,
            modalClass,
        } = this.state;
        return (
            <div className="edit-profile-screen">
                <TitleHeader title="Edit Profile" />
                {loading && <Loader />}
                <h4>Personal Information</h4>
                {error && <p className="page-error">{error}</p>}
                {
                    profile &&
                    <React.Fragment>
                        <div className="input-container">
                            <label htmlFor="firstName" className="half">
                                <span className="input-name">Firstname</span>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    onChange={this.handleInputs}
                                    value={profile.firstName}
                                />
                            </label>
                            <label htmlFor="lastName" className="half">
                                <span className="input-name">Lastname</span>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    onChange={this.handleInputs}
                                    value={profile.lastName}
                                />
                            </label>
                            <label htmlFor="email">
                                <span className="input-name">Email</span>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    onChange={this.handleInputs}
                                    value={profile.email}
                                />
                            </label>
                            <label htmlFor="phone">
                                <span className="input-name">Phone Number</span>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    onChange={this.handleInputs}
                                    value={profile.phone}
                                />
                            </label>
                            <label htmlFor="address">
                                <span className="input-name">Address</span>
                                <textarea
                                    name="address"
                                    id="address"
                                    value={profile.address}
                                    onChange={this.handleInputs}
                                />
                            </label>
                        </div>
                        <button className="primary-button" onClick={this.updateProfile}>Save Changes</button>
                    </React.Fragment>
                }
                <Nav page="profile" />

                <Modal modalClass={modalClass}>
                    <SuccessAnimation close={this.closeModal} />
                </Modal>
            </div>
        )
    }
}

export default withRouter(EditProfile); 
