import React, { Component } from 'react';
import TitleHeader from '../../components/header/TitleHeader';
import Nav from '../../components/nav/Nav';

import { generateColor } from '../../utils/helper';

import './CompletedRequest.scss'

export default class CompletedRequest extends Component {
    render() {
        return (
            <div className="completed-request-screen">
                <TitleHeader title="Completed Request" />
                <div className="card">
                    <div className="details">
                        <div className="badge" style={{ background: generateColor() }}>CA</div>
                        <p className="name">Chidinma Aniugbo</p>
                        <div className="kit-number">
                            <p>KIT NUMBER</p>
                            <h4>5673AD</h4>
                        </div>
                    </div>
                    <div className="delivery-details">
                        <div className="item-row">
                            <p className="label">Time of delivery</p>
                            <p className="content">2:00 pm</p>
                        </div>
                        <div className="item-row">
                            <p className="label">Date of delivery</p>
                            <p className="content">Jul 28, 2020</p>
                        </div>
                        <div className="item-row">
                            <p className="label">Gender</p>
                            <p className="content">female</p>
                        </div>
                        <div className="item-row">
                            <p className="label">Address</p>
                            <p className="content">25 Sanusi fafuwa str, Victoria island, lagos.</p>
                        </div>
                    </div>
                </div>
                <Nav page="home"/>
            </div>
        )
    }
}
