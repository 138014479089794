import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getRequest, putRequest } from '../../api/Api';
import TitleHeader from '../../components/header/TitleHeader';
import Nav from '../../components/nav/Nav';
import ConfirmModal from '../../components/confirmModal/ConfirmModal';
import Modal from '../../components/modal/Modal';
import { generateColor } from '../../utils/helper';
import { CircleCheckIcon } from '../../components/icons/Icons';
import Loader from '../../components/loader/Loader';


import scan_icon from '../../assets/scan-icon.svg';
import clock_icon from '../../assets/clock-icon.svg';
import arrow_down from '../../assets/arrow-down.svg';

import './PendingRequest.scss'

class PendingRequest extends Component {
    state = {
        modalClass: '',
        // modalClass: 'animated-modal',
        selectedReason: null,
        reasons: [
            'Tester not available on arrival',
            'Didn’t pick up call',
            'Kit not found'
        ],
        address: false,
        request: null,
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { id } = params;
        this.setState({
            id
        })
        this.loadRequests(id);
    }

    loadRequests = async (id) => {
        this.setState({ loading: true })
        let params = { id };

        const result = await getRequest('rider/requests', params);
        const { response, error } = result;
        this.setState({
            loading: false,
            request: response ? response : null,
            error,
        })
    }

    rescheduleRequest = async (id) => {
        const { reasons, selectedReason } = this.state;
        if (selectedReason !== null){
            this.setState({ modalLoading: true });
            const body = { reason: reasons[selectedReason] };
            const result = await putRequest(`rider/request/failed/${id}`, body);
            const { response, error } = result;
            if(response){
                this.goBack('/home/pending');
            }
            if(error){
                this.setState({
                    modalLoading: false,
                    modalError: error,
                })
            }
        } else {
            this.setState({
                modalError: 'Select a reason to proceed.'
            })
        }
    }

    openModal = () => {
        this.setState({ modalClass: 'animated-modal' })
    }

    closeModal = () => {
        this.setState({ 
            modalClass: 'animated-modal out',
            selectedReason: null,
            modalError: ''
        })
    }

    openScanner = (id, caseId) => {
        const { request: { kitCode } } = this.state;
        this.props.history.push({
            pathname: `/scan-qr/${id}/${caseId}`,
            kitCode
        })
    }

    selectReason = (index) => {
        this.setState({
            selectedReason: index,
            modalError: '',
        })
    }

    toggleAddress = () => this.setState({ address: !this.state.address });

    goBack = (route) => {
        this.props.history.push({
            pathname: route,
        })
    }

    render() {
        const {
          modalClass,
          reasons,
          selectedReason,
          address,
          loading,
          modalLoading,
          request,
          modalError,
        } = this.state;
        const tester = request && request.case;
        return (
            <div className="pending-request-screen">
                <TitleHeader title="Pending Request" goBack={() => this.goBack('/home/pending')}/>
                {loading && <Loader />}
                <div className="card">
                    {
                        request &&
                        <React.Fragment>
                            <div className="details">
                                <div className="badge" style={{ background: generateColor() }}>
                                    {tester.firstName[0]}{tester.lastName[0]}
                                </div>
                                <p className="name">{tester.firstName} {tester.lastName}</p>
                                <div className="kit-number">
                                    <p>KIT NUMBER</p>
                                    <h4>{request.kitCode}</h4>
                                </div>
                            </div>
                            <div className={`address-div ${address ? 'show' : ''}`}>
                                <div className="top" onClick={this.toggleAddress}>
                                    <p>Delivery Address</p>
                                    <img src={arrow_down} alt="" />
                                </div>
                                <div className="main">
                                    {tester.deliveryAddress}
                            </div>
                            </div>
                            <div className="actions">
                                <button className="primary-button" onClick={() => this.openScanner(request.id, tester.id)}>
                                    <img src={scan_icon} alt="" />
                                Scan QR Code
                            </button>
                                <button className="primary-button-outline" onClick={this.openModal}>
                                    <img src={clock_icon} alt="" />
                                Suggest Reschedule
                            </button>
                            </div>
                        </React.Fragment>
                    }
                </div>
                <Modal modalClass={modalClass} >
                    <ConfirmModal
                        title="Why are you suggesting a reschedule?"
                        buttonText="Proceed"
                        loading={modalLoading}
                        action={() => this.rescheduleRequest(request.id)}
                        cancel={this.closeModal}
                        error={modalError}
                    >
                        <div className="reschedule-reason">
                            {
                                reasons.map((text, index) => (
                                    <div className="item" key={index} onClick={() => this.selectReason(index)}>
                                        <p>{text}</p>
                                        <CircleCheckIcon color={selectedReason === index ? '#16B844' : '#C0BECA'} />
                                    </div>
                                ))
                            }
                        </div>
                    </ConfirmModal>
                </Modal>
                <Nav page="home" />
            </div>
        )
    }
}

export default withRouter(PendingRequest);
