import React from 'react';
import { generateColor } from '../../utils/helper';
import moment from 'moment';

// import { ArrowCircleIcon } from '../icons/Icons.js';

import './HistoryCompleted.scss';

const HistoryCompleted = ({ history }) => {
    return (
        <div className="history-list">
            {
                history.map(item => {
                    const { calendar, data } = item;
                    let date;
                    if(moment().format('YYYY-MM-DD') === moment(calendar, 'YYYY-MM-DD').format('YYYY-MM-DD')) date = 'Today'
                    else if (moment('YYYY-MM-DD').subtract(1, 'day') === moment(calendar, 'YYYY-MM-DD').format('YYYY-MM-DD')) date = 'Yesterday'
                    else date = moment(calendar, 'YYYY-MM-DD').format('MMM D, YYYY');
                    return(
                        <React.Fragment key={calendar}>
                            <div className="day-label">{date}</div>
                            <div className="list">
                            {
                                data.map(item => {
                                    const { id, case: { firstName, lastName, phone_no } } = item;
                                    return(
                                        <div className="item" key={id}>
                                            <div className="badge" style={{ background: generateColor() }}>
                                                {firstName[0]}{lastName[0]}
                                            </div>
                                            <div className="name-number">
                                                <h4>{firstName} {lastName}</h4>
                                                <p>{phone_no}</p>
                                            </div>
                                            {/* <ArrowCircleIcon/> */}
                                            <div className="line"></div>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </React.Fragment>
                    )
                })
            }
        </div>
    )
}

export default HistoryCompleted; 
